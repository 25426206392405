import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Virgo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Virgo Facts
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/virgo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Virgo</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Virgo Career</h4>
              
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Virgo Facts </h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Virgo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-man");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/virgo/virgo-love");
                  }}>
                  <h5 className="font-semibold text-black">Virgo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">
                    Interesting facts about Virgo
                  </p>
                  <br/>
                  Virgo is an earth sign and represents the harvest. Virgo is
                  associated with the 6th house of health, work, and service.{" "}
                  <br />
                  Virgo is one of the zodiac signs that are most misunderstood
                  today. Many people think of Virgo as being very picky and
                  fussy when Virgo is a very loyal and hardworking individual.
                  It is just that Virgo has very high standards, which in turn
                  means that Virgo does not compromise on quality. They also
                  have a very analytical mind and will take a very structured
                  approach to solve a problem.
                  <br />
                  <br />
                  What works for Virgo It is a sign of service and health. You
                  like helping others and making other people happy. You are
                  interested in health and wellness. You want to eat healthily
                  and exercise. You like animals, beauty, eating healthy food,
                  and orderliness. You are more interested in giving than
                  receiving. You get delighted when people agree with you.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">What about Virgo's common traits</p><br/>
                  ● Virgos are known for being attentive. <br />
                  ● They pay close attention to the people around them, the
                  atmosphere of a room, and the tiniest details. <br />
                  ● They're often called on to lend their keen observation
                  skills to help others. <br />
                  ● They're also known for focusing on one thing for long
                  periods.
                  <br />
                  ● You're People-pleaser and a natural caretaker. <br />
                  ● You want to make the people around you happy, and you pay
                  close attention to their needs, which allows you to respond
                  quickly to the ever-changing needs of your family, friends,
                  and coworkers. <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">What about Virgo's MOTTO</p><br/>
                  The Virgo motto is clear: Virgo is all about the mind. The
                  Virgo mind is the most powerful tool we have. <br />
                  <br />
                  The Virgo thought is to be the best, do the best, and have the
                  best. Their mind constantly analyzes, searches for mistakes
                  and flaws, and finds ways to improve. You're all about
                  perfection, and when Virgos reach a goal, they immediately set
                  their sights on the next one.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Virgo's interest in life:</p>
                  <br />
                  You are the one who values knowledge and learning above all
                  else. It seems like they spend a lot of time studying, but
                  they also have a lot of energy when it comes to learning new
                  things. They are very critical of themselves and their
                  abilities, making it difficult to evaluate their performance
                  objectively. Luckily, one can use self-criticism to one's
                  advantage.
                  <br />
                  <br />
                  ● It helps them be good problem solvers and able to make good
                  decisions. <br />
                  ● Things Virgo like
                  <br />
                  ● You are known for being hardworking and practical. <br />
                  ● You are analytical and curious, and they enjoy learning new
                  things and coming up with solutions to problems. <br />
                  ● You are also known for being perfectionists — if Virgos
                  decide they're going to do something, they'll do their best to
                  do it well. Sometimes, Virgos can be critical of themselves
                  and others.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Virgo's hidden talents</p><br/>
                  The hardworking and methodical sign, Virgo is the perfect
                  candidate for a job title like "detective." Virgo is often a
                  natural-born detective with a mind for solving puzzles and a
                  thirst for knowledge. Virgo is always looking for a challenge
                  and a new dilemma to solve no matter what they do.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Things that Virgo hate</p><br/>
                  You hate being around messy people or don't take
                  responsibility for their actions or the state of their
                  surroundings. <br />
                  You also hate it when people don't follow through on their
                  commitments or don't respect the boundaries you've set. And
                  you hate being around people who are argumentative or who
                  choose to be wrong rather than admit when they're wrong. The
                  situation makes it difficult for you to be around people.
                  Still, you do your best to be understanding and patient when
                  others are being messy or aren't being respectful of your
                  rules.
                  <br />
                  <br />
                  ● You dislike sloppiness and disorganization in all forms.{" "}
                  <br />
                  ● You also hate being seen as weak, which means you struggle
                  to set boundaries with others.
                  <br /><br/>
                  
                  <p className="playfair text-black text-xl md:text-3xl">Virgo, pay attention</p><br/>
                  You are attracted to things that are orderly, well-planned,
                  and well-executed. You hate chaos, clutter, and confusion, and
                  you love a well-organized, clean home. You are also attracted
                  to people who are good at their responsibilities, such as
                  taking care of their belongings, making sure things are pure,
                  and keeping their surroundings organized.
                  <br />
                  <br />
                  You are conscientious and detail-oriented, which means you
                  need things done differently. You are also attracted to those
                  with discipline, good manners, and perfection. You are also
                  attracted to those with ambition, confidence, and
                  intelligence.
                  <br />
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Things that irritate Virgo</p><br/>
                  You can be irritable when things aren't done the right way or
                  when things don't go their way. You also get irritated when
                  people don't think for themselves and instead make decisions
                  based on other people's opinions. They want people to think
                  for themselves and make their own decisions.
                  <br />
                  <br />
                  <p className="font-bold inline-block text-orange-500">Virgo does not have time for:</p><br/>
                  ● You don't have time for
                  people who want to be the center of attention and make others
                  feel small. <br></br>● Finding ways to improve yourself, the
                  people around them, and the world around them. <br></br>● You
                  aren't interested in small talk but instead, want to find ways
                  to improve the world and their lives.<br></br>
                  <br />
                  <p className="playfair text-black text-xl md:text-3xl">Perfect gift for Virgo</p><br/>
                  Virgos are known for being health-focused. You probably have a
                  balanced diet and workout routine. But that doesn't mean you
                  can't improve even more. For example, you might want to invest
                  in some new gym equipment to help you get in shape.
                  <br />
                  <br />
                  You have taken the first step on your journey of
                  self-discovery by answering a few questions about yourself.
                  Personality tests are a way to understand better who you are
                  and what motivates you. By learning more about yourself, you
                  can better understand why you do the things you do and figure
                  out ways to improve. Keep exploring!
                  <br />
                  <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
